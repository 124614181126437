import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Image } from 'grommet'
import { qlikBrand } from '../../branding'
import { withRouter } from 'react-router-dom'
import logo from '../../locman_logo.png'
import { Auth } from 'aws-amplify';

export class Header extends Component {
  labels = {
    uiBus: 'BUS',
    search: 'SEARCH',
    uiBugfix: 'BUGFIX',
    queries: 'QUERIES',
    quotes: 'QUOTES',
    logout: 'LOGOUT'
  }

  render () {
    const { size, status, history } = this.props
    const { bugfix, query, ui } = status
    let buttonColors = {}
    if (bugfix) { buttonColors.bugfix = qlikBrand.flood.turquoise }
    else { buttonColors.bugfix = 'red'}
    if (query) { buttonColors.query = qlikBrand.flood.turquoise }
    else { buttonColors.query = 'red'}
    if (ui) { buttonColors.ui = qlikBrand.flood.turquoise }
    else { buttonColors.ui = 'red'}
    if (size === 'medium') {
      return (
        <Box justify='between' align='center'>
          <Box align='center' fill>
            <Box height='xsmall' width='small'>
              <Image fit='contain' src={logo} />
            </Box>
          </Box>
          <Box direction='row-responsive' gap='medium' justify='center' align='center' fill>
            <Button primary color={buttonColors.ui} label={this.labels.uiBus} onClick={() => history.push('/')} />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.search} onClick={() => history.push('/search')} />
            <Button primary color={buttonColors.bugfix} label={this.labels.uiBugfix} onClick={() => history.push('/bugfix')} />
            <Button primary color={buttonColors.query} label={this.labels.queries} onClick={() => history.push('/queries')} />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.quotes} onClick={() => history.push('/quotes')}  />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.logout} onClick={() => Auth.signOut()} />
          </Box>

        </Box>
      )
    }
    if (size === 'large') {
      return (
        <Box direction='row' justify='between' align='center'>
          <Box fill>
            <Box height='xsmall' width='small'>
              <Image fit='contain' src={logo} />
            </Box>
          </Box>
          <Box direction='row-responsive' gap='medium' justify='center' align='center' fill>
            <Button primary color={buttonColors.ui} label={this.labels.uiBus} onClick={() => history.push('/')} />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.search} onClick={() => history.push('/search')} />
            <Button primary color={buttonColors.bugfix} label={this.labels.uiBugfix} onClick={() => history.push('/bugfix')} />
            <Button primary color={buttonColors.query} label={this.labels.queries} onClick={() => history.push('/queries')} /> 
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.quotes} onClick={() => history.push('/quotes')}  />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.logout} onClick={() => Auth.signOut()} />
          </Box>
          <Box direction='row-responsive' gap='medium' justify='end' align='center' fill='horizontal' />
        </Box>
      )
    } else {
      return (
        <Box align='center' gap='medium' fill>
          <Box height='xsmall' width='small'>
            <Image fit='contain' src={logo} />
          </Box>
          <Box gap='medium' justify='center' align='center' fill>
            <Button primary color={buttonColors.ui} label={this.labels.uiBus} onClick={() => history.push('/')} />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.search} onClick={() => history.push('/search')} />
            <Button primary color={buttonColors.bugfix} label={this.labels.uiBugfix} onClick={() => history.push('/bugfix')} />
            <Button primary color={buttonColors.query} label={this.labels.queries} onClick={() => history.push('/queries')} />  
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.quotes} onClick={() => history.push('/quotes')} />
            <Button primary color={qlikBrand.flood.turquoise} label={this.labels.logout} onClick={() => Auth.signOut()} />
          </Box>
        </Box>
      )
    }
  }
}

Header.propTypes = {
  size: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired
}

export default withRouter(Header)
