

const utils = {
	makeCSV(columns, rows) {
		let csvContent = "data:text/csv;charset=utf-8,";
		const headers = ["id", "product", "orderedIn", "handbackDate", "language", "vendor", "type", "handoffName", "currency", "generatedFromMetrics", "managementFee", "actualInEUR", "jiraID", "comment"];
		csvContent += headers.join(",") + "\r\n";

		rows.forEach((data) => {
			let row = Object.values(data).join(",");
			csvContent += row + "\r\n";
		});

		return csvContent;

	},
	download(data) {
		var encodedUri = encodeURI(data);
		var link = document.createElement("a");
		link.id = 'temp_download';
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "quoets.csv");
		document.body.appendChild(link); // Required for FF

		link.click();
		link.remove();
	},
	exportCSV(columns, rows) {
		if (!rows || !rows.length) return;

		const data = this.makeCSV(columns, rows);
		this.download(data);
	}
};

export default utils;
