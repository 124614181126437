import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'

export class ActionableNotice extends Component {
  render () {
    const { description, notice, width } = this.props
    return (
      <Box width={width}>
        <Box direction='row-responsive' background='white' width='xlarge' height='xsmall' pad='medium'  gap='small' round fill>
          <Box align='start' justify='center' pad={{left: 'small'}} width='small'>
            <Text size='xlarge'>{description}</Text>
          </Box>
          <Box align='end' justify='center' pad={{left: 'small', right: 'small'}} fill>
            <Text size='xlarge'>{notice}</Text>
          </Box>
        </Box>
      </Box>
    )
  }
}

ActionableNotice.propTypes = {
  description: PropTypes.string.isRequired,
  notice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  width: PropTypes.string.isRequired
}


export default ActionableNotice