import React, { Component } from 'react';
import { Box, Text, Stack, Meter, Image, Collapsible, Button } from 'grommet';
import { Close, Down } from 'grommet-icons';
import { qlikBrand } from '../../branding';
import ActionableNotice from '../../components/ActionableNotice';
import MetricBox from '../../components/MetricBox';
import Spinner from 'react-svg-spinner';
import busImage from '../../school-bus_256.png';
import BusBreakdown from '../../components/BusBreakdown';
import Notes from '../Notes'
import clone from 'lodash/clone';


export class BusOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextBusOpen: false,
      recentProjects: {
        open: []
      },
      notesLayerOpen: undefined,
      creatingNote: false
    };
  }


  onNotesLayerOpen = () => this.setState({ notesLayerOpen: true });

  onNotesLayerClose = () => this.setState({ notesLayerOpen: undefined });

  setCreatingNote = (val) => this.setState({ creatingNote: val });

  renderRecentProject = ({ project, index }) => {
    const { recentProjects, notes } = this.state;
    const { setNotes } = this.props;
    let commitCount = 0;
    let fileCount = 0;
    let repoCount = 0;
    const statusMap = {
      10: 'Creating',
      20: 'Translating',
      30: 'Handback',
      35: 'Handback2',
      40: 'Done'
    };
    project.repositories.forEach(repo => {
      repoCount++;
      repo.files.forEach(file => {
        fileCount++;
        commitCount += file.commits.length;
      });
    });
    return (
      <Box key={`recent_project_${project.name}_${project.stringsModified}`} align='center'>
        <Box background='white' height='flex' pad='small' width='large' round>
          <Box direction='row-responsive' justify='between' pad={{ left: 'small', right: 'small' }}>
            <Box>
              <Box direction='row-responsive' gap='small' fill>
                <Box justify='center'><Text size='large'>{project.name}</Text></Box>
              </Box>
              <Box direction='row-responsive' gap='small' align='center' justify='center' fill>
                <Box border pad={{ left: 'small', right: 'small', top: 'xsmall', bottom: 'xsmall' }} justify='center' round>
                  <Text size='small'>{`${project.stringsModified} strings`}</Text>
                </Box>
                <Box border pad={{ left: 'small', right: 'small', top: 'xsmall', bottom: 'xsmall' }} justify='center' round>
                  <Text size='small'>{`${commitCount} commits`}</Text>
                </Box>
                <Box border pad={{ left: 'small', right: 'small', top: 'xsmall', bottom: 'xsmall' }} justify='center' round>
                  <Text size='small'>{`${repoCount} repos`}</Text>
                </Box>
                <Box border pad={{ left: 'small', right: 'small', top: 'xsmall', bottom: 'xsmall' }} justify='center' round>
                  <Text size='small'>{`${fileCount} files`}</Text>
                </Box>
                <Box border pad={{ left: 'small', right: 'small', top: 'xsmall', bottom: 'xsmall' }} justify='center' round>
                  {project.awsStatus !== 'RUNNING' && project.awsStatus !== 'SUCCEEDED' ? (<Text size='small' color='red'>{`AWS Status: ${project.awsStatus}`}</Text>) : (<Text size='small' color='green'>{`AWS Status: ${project.awsStatus}`}</Text>)}
                </Box>
              </Box>
            </Box>

            <Box align='center' pad='xsmall'>
              <Stack anchor='center'>
                <Meter
                  type="circle"
                  background="light-2"
                  values={[{ value: project.statusCode, color: qlikBrand.flood.aquamarine }, { value: 40 - project.statusCode, color: 'light-2' }]}
                  size="xsmall"
                  thickness="small"
                />
                <Box direction="row" align='end' pad={{ bottom: "xsmall" }}>
                  <Text size="small">
                    {statusMap[project.statusCode]}
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Collapsible open={!recentProjects.open[index]}>
          <Box>
            <Button onClick={() => this.toggleRecentBreakdownOpen(index)} icon={<Down />} />
          </Box>
        </Collapsible>
        <Collapsible open={recentProjects.open[index]}>
          <Box align='center'>
            <Button onClick={() => this.toggleRecentBreakdownOpen(index)} icon={<Close color='red' />} />
            <BusBreakdown repositories={project.repositories} notes={notes} setNotes={setNotes} onNotesLayerOpen={this.onNotesLayerOpen} setCreatingNote={this.setCreatingNote} />
            <Button onClick={() => this.toggleRecentBreakdownOpen(index)} icon={<Close color='red' />} />
          </Box>
        </Collapsible>
      </Box>
    );
  };

  toggleRecentBreakdownOpen(index) {
    const { recentProjects } = this.state;
    let open = clone(recentProjects.open);
    open[index] = !open[index];
    this.setState({ recentProjects: { open } });
  }

  renderRecent = ({ projects, activeProjects }) => {
    if (!projects || !projects.length) {
      return (<Box align='center' pad='small' gap='small' fill><Spinner /></Box>);
    }

    return (
      <Box align='center' pad='small' gap='small' fill>
        <Box direction='row' width='large' height='xxsmall' pad='medium' round>
          <Box align='start' justify='center' fill>
            <Text size='xlarge'>Recent</Text>
          </Box>
          <Box align='end' justify='center' fill>
            <Text size='xlarge'>{`Active: ${activeProjects}`}</Text>
          </Box>
        </Box>
        {projects.map((project, index) => {
          return this.renderRecentProject({ project, index });
        })}
      </Box>
    );
  };

  renderTimer() {
    const { nextBusCountdown } = this.props;
    if (!nextBusCountdown.hours && !nextBusCountdown.minutes) {
      return <Spinner />;
    }
    const { days, hours, minutes } = nextBusCountdown;
    let timeArray = [days, hours, minutes];
    let timeAbbr = ['d', 'h', 'm'];
    let displayString = '';
    timeArray.forEach((t, index) => {
      let time = t.toString();
      if (time.length < 2) {
        timeArray[index] = '0' + time;
      }
      displayString += timeArray[index];
      displayString += timeAbbr[index];
      displayString += ' ';
    });
    return displayString;
  }

  toggleNextBusOpen() {
    const { nextBusOpen } = this.state;
    this.setState({ nextBusOpen: !nextBusOpen });
  }

  showBusButton(data) {
    return Object.values(data).some(Number.isInteger);
  }

  render() {
    const { overview, repositories, nextBusDate, notes, setNotes } = this.props;
    const { nextBusOpen, notesLayerOpen, creatingNote } = this.state;
    let notesCount = notes.length;
    if (notesCount === undefined) {
      notesCount = <Spinner />
    }
    
    return (
      <Box>
        <Box>
          <Box align='center' pad={{ top: 'small', bottom: 'small' }} gap='medium'>
            <ActionableNotice description='Next Bus' notice={this.renderTimer()} width='large' />
            <Box direction='row-responsive' gap='medium' width='large'>
              <MetricBox description='Strings' metric={overview.modifications.strings} />
              <MetricBox description='Words' metric={overview.modifications.words} />
              <MetricBox description='Repositories' metric={overview.modifications.repositories} />
              <MetricBox description='Files' metric={overview.modifications.files} />
              <MetricBox description='Notes' metric={notesCount} onClick={()=>this.onNotesLayerOpen()} />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box align='center'>
            <Collapsible open={!nextBusOpen}>
              <Box align='center' pad='small' gap='small' size='large' width='large'>
                {this.showBusButton(overview.modifications)
                  ? <Button onClick={() => this.toggleNextBusOpen()}><Image fit='contain' src={busImage} /></Button>
                  : <Spinner />
                }
              </Box>
            </Collapsible>
            <Collapsible open={nextBusOpen}>
              <Box align='center'>
                <Button onClick={() => this.toggleNextBusOpen()} icon={<Close color='red' />} />
                <BusBreakdown repositories={repositories} isBusLookAhead={true} nextBusDate={nextBusDate} notes={notes} setNotes={setNotes} onNotesLayerOpen={this.onNotesLayerOpen} setCreatingNote={this.setCreatingNote}/>
                <Button onClick={() => this.toggleNextBusOpen()} icon={<Close color='red' />} />
              </Box>
            </Collapsible>
          </Box>

        </Box>
        <Box pad={{ top: 'small', bottom: 'large' }}>
          {this.renderRecent({ projects: overview.projects, activeProjects: overview.activeProjects })}
        </Box>
        {notesLayerOpen && (
          <Notes notes={notes} onNotesLayerClose={this.onNotesLayerClose} nextBusDate={nextBusDate} creatingNote={creatingNote} setNotes={setNotes} />
        )}
      </Box>
    );
  }
}

export default BusOperations;
