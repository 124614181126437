import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from 'grommet'

export class MetricBox extends Component {
  render () {
    const { description, metric, onClick } = this.props
    return (
      <Box background='white' justify='center' pad='small' fill round onClick={onClick}>
        <Box align='center' justify='center' height='xxsmall' width='flex'>
          <Text size='xxlarge'>{metric}</Text>
        </Box>
        <Box align='center' justify='center' height='xxsmall' width='flex'>
          <Text size='large'>{description}</Text>
        </Box>
      </Box>
    )
  }
}

MetricBox.propTypes = {
  description: PropTypes.string.isRequired,
  metric: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ]).isRequired
}

export default MetricBox