export default {
    apiGateway: {
      REGION: process.env.REACT_APP_AWS_REGION,
      URL: process.env.REACT_APP_URL,
      UI_URL: process.env.REACT_APP_LOCMAN_UI_URL,
      QUERIES_URL: process.env.REACT_APP_LOCMAN_QUERIES_URL
    },
    cognito: {
      REGION: process.env.REACT_APP_AWS_COGNITO_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
    }
  };