import React, { Component } from 'react'
import { Box, Heading } from 'grommet'
import PropTypes from 'prop-types'
import ProjectCard from '../../components/BugfixProjectCard'
import Spinner from 'react-svg-spinner'
import cloneDeep from 'lodash/cloneDeep'
import { API } from 'aws-amplify'

const renderBugfixProjects = ({ bugfixProjects, fetchBugfixListInProgress, toggleBug }) => {
  if (fetchBugfixListInProgress) {
    return (<Box align='center'><Spinner size='80px'/></Box>)
  }
  if (!bugfixProjects.length) {
    return (
      <Box align='center'>No projects!</Box>
    )
  } else {
    return (
      <Box align='center'>
        {bugfixProjects.map((project, index) => (
          <ProjectCard key={project._id} project={project} toggleBug={toggleBug} projectIndex={index} />
        ))}
      </Box>
    )
  }
}

export class BugfixOperations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bugfixList: [],
      fetchBugfixListInProgress: true
    }
    this.toggleBug = this.toggleBug.bind(this)
  }

  async componentDidMount () {
    API.get('LocmanUI', 'bugfixes')
    .then(res => {
      let bugfixList = res.map(project => {
        project.bugs.forEach(bug => (bug.expanded = false))
        return project
      })
      this.setState({ bugfixList, fetchBugfixListInProgress: false })
    })
    .catch(err => {
      console.log(err)
      this.setState({ fetchBugfixListInProgress: false })
    })
  }

  toggleBug (project, bug) {
    const bugfixList = cloneDeep(this.state.bugfixList)
    bugfixList[project].bugs[bug].expanded = !bugfixList[project].bugs[bug].expanded
    this.setState({ bugfixList })
  }

  render () {
    const { bugfixList, fetchBugfixListInProgress } = this.state
    return (
      <Box align='stretch' fill='horizontal' jusify='center'>
        <Box align='center'><Heading level='2'>Bugfix Projects</Heading></Box>
        {renderBugfixProjects({
          bugfixProjects: bugfixList,
          fetchBugfixListInProgress,
          toggleBug: this.toggleBug
        })}
      </Box>
    )
  }
}

BugfixOperations.propTypes = {
  bugfixList: PropTypes.array.isRequired
}

export default BugfixOperations