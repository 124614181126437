import React, { Component } from 'react'
import { Box, Text, Grid } from 'grommet'
import { qlikBrand } from '../../branding'

export class ResourceCard extends Component {
  render () {
    const { resource } = this.props
    return (
      <Box background={qlikBrand.flood.slate} pad='medium' round>
        <Box>
          <Grid
            rows={['flex', 'flex', 'flex', 'flex']}
            columns={['xsmall', 'flex']}
            gap='small'
            fill
            areas={[
              { name: 'componentLabel', start: [0, 0], end: [0, 0] },
              { name: 'component', start: [1, 0], end: [1, 0] },
              { name: 'resourceLabel', start: [0, 1], end: [0, 1] },
              { name: 'resource', start: [1, 1], end: [1, 1] },
              { name: 'descriptionLabel', start: [0, 2], end: [0, 2] },
              { name: 'description', start: [1, 2], end: [1, 2] },
              { name: 'localesLabel', start: [0, 3], end: [0, 3] },
              { name: 'locales', start: [1, 3], end: [1, 3] }
            ]}
          >
            <Box gridArea='componentLabel' fill>
              <Text weight='bold'>Component</Text>
            </Box>
            <Box gridArea='component' fill>
              <Text>{ resource.componentId.name }</Text>
            </Box>
            <Box gridArea='resourceLabel' fill>
              <Text weight='bold'>Resource</Text>
            </Box>
            <Box gridArea='resource' fill>
              <Text>{ resource.resourceId.name }</Text>
            </Box>
            <Box gridArea='descriptionLabel' fill>
              <Text weight='bold'>Description</Text>
            </Box>
            <Box gridArea='description' fill>
              <Text>{ resource.description }</Text>
            </Box>
            <Box direction='row' gridArea='localesLabel'fill>
              <Text weight='bold'>Locales</Text>
            </Box>
            <Box gridArea='locales' fill>
              <Box direction='row-responsive' wrap gap='xsmall'>
                {resource.locales.map((locale) => {
                  return <Box key={locale} justify='center' pad='xxsmall' background={qlikBrand.flood.stone} round><Text size='xsmall'>{locale}</Text></Box>
                })}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    )
  }
}

export default ResourceCard
