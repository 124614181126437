import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from 'grommet'

export class DisplayImageBugfix extends Component {
  render () {
    const { image } = this.props
    return (
      <Box>
        <Image src={image} />
      </Box>
    )
  }
}

DisplayImageBugfix.propTypes = {
  image: PropTypes.string.isRequired
}


export default DisplayImageBugfix