import React, { Component } from 'react'
import { Box, Collapsible, Button } from 'grommet'
import ResourceCard from '../ResourceCard'

export class BugCard extends Component {
  render () {
    const { bug, toggleBug, projectIndex, bugIndex } = this.props
    return (
      <Box>
        <Box pad={{ top: 'small', bottom: 'small' }}><Button label={bug.jiraId} onClick={() => toggleBug(projectIndex, bugIndex)} /></Box>
        <Collapsible open={bug.expanded}>
          <Box width='large' gap='small'>{bug.resources.map(resource => (<ResourceCard key={resource._id} resource={resource} />))}</Box>
        </Collapsible>
      </Box>
    )
  }
}

export default BugCard
