import React, { Component } from 'react'
import moment from 'moment'
import {API} from "aws-amplify"
import { 
  Box,
  Layer,
  Text,
  TextArea,
  Button,
  Spinner,
  FileInput,
  Image
} from 'grommet'


export class AddNote extends Component {
  constructor (props) {
    super(props)
    this.state = {
      noteValue: `String ID: ${props.stringSelected.name}\nString Value: ${props.stringSelected.value}`,
      notePic: undefined,
      submitting: false,
      failed: false
    }
    this.defaultValue = `String ID: ${props.stringSelected.name}\nString Value: ${props.stringSelected.value}`
  }

  onClickReset() {
    this.setState({
      noteValue: this.defaultValue,
      submitting: false,
      failed: false
    })
  }


  onClickCloseMsg() {
    this.setState({
      failed: false
    })
  }

  onClickSubmit() {
    const {noteValue, notePic} = this.state;
    this.setState({
      submitting: true
    })

    const {  nextBusDate, repoSelected} = this.props;
    // console.log(this.state.notePic)
    // const image = await this.readFile(this.state.notePic)
    // console.log(image);

    const data = {
      body: {
        projectName: `${repoSelected.category}_UI_${moment(nextBusDate).format('YYYY-MM-DD')}`,
        note: noteValue,
        notePic,
        category: repoSelected.category,
        repositoryId: repoSelected.id
      }
    }

    this.setState({
      submitting: true,
      failed: false,
    })

    API.post('Notes', 'notes', data)
      .then((res) => {
        this.setState({
          submitting: false,
          failed: false
        })
        this.props.onExit({
          showPopupNote: false,
          newNote: res.note
        });
      })
      .catch(err => {
        console.log(err)
        this.setState({
          submitting: false,
          failed: true
        })
      })
  }


  async readFile (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      }
      reader.onerror = function (error) {
        reject(error)
      }
    })
  }

  onChange = (e) => {

    this.setState({
      noteValue: e.target.value
    })
  }

  onChangePic = async (e) => {
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      const image = await this.readFile(fileList[0])
      this.setState({
        notePic: image
      })
    } else {
      this.setState({
        notePic: undefined
      })
    }
  }



  render () {
    const {
      submitting,
      failed,
      noteValue
    } = this.state

    return (<Layer
      modal={true}
      margin='none'
      position="center"
      onEsc={() => this.props.onExit({showPopupNote: false})}
      onClickOutside={() => this.props.onExit({showPopupNote: false})}
    >
      <Box alignSelf='stretch' overflow='scroll' >

        <Text  alignSelf='center' size='large' weight='bold'>Add Note</Text>
        <Box alignSelf='center'  size='large' pad='small' gap='xxsmall' justify="between" align="center" >

            <Box direction="row" size="large" width='large' >
                <TextArea
                id="noteValue"
                name="noteValue"
                value={noteValue}
                onChange={(e) => this.onChange(e)}
                />
            </Box>

            <Box direction="row" size="large" width='large' >
              <FileInput
                id="notePic"
                name="notePic"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => this.onChangePic(e)}
              />
            </Box>
            {this.state.notePic && <Box direction="row" size="large" width='large' >
            <Image
                fit="cover"
                src={this.state.notePic}
              />
            </Box>}

            <Box direction="row" justify="between" margin={{ top: 'medium' }}>
              {!submitting && noteValue && noteValue.length > 0 && <Button type="submit" primary label="Submit" onClick={() => this.onClickSubmit()} />}
              {submitting && (<Box direction='row' gap="small">
                <Spinner size='small' />
                <Text>Submit In Progress...</Text>
                </Box>
              )}
              <Button type="reset" label="Reset Form" onClick={() => this.onClickReset()}/>
              <Button label="Cancel" onClick={() => this.props.onExit({showPopupNote: false})} />
            </Box>
        </Box>
        {
          failed && <Text alignSelf='center' color={'red'}>Ooops, looks like something went wrong!</Text>
        }
      </Box>
    </Layer>
    )
  }
}

export default AddNote
