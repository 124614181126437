import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import { Grommet, Box, Image, ResponsiveContext, Text } from 'grommet'
import { Slack } from 'grommet-icons'
import { theme } from './branding'
import Spinner from 'react-svg-spinner'
import BusOperations from './containers/BusOperations'
import Search from './containers/Search'
import Quotes from './containers/Quotes'
import moment from 'moment'
import countdown from 'countdown'
import qlikLogo from './Qlik-Logo_RGB.png'
import Header from './components/Header'
import BugfixOperations from './containers/BugfixOperations'
// import Trends from './containers/Trends'
import Queries from './containers/QueriesOperations'
import { withAuthenticator } from 'aws-amplify-react'
import Amplify, {API} from "aws-amplify";
import config from "./config"

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "NextUIBus",
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION
      }, {
        name: "LatestUIProjects",
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION        
      }, {
        name: "Status",
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION        
      }, {
        name: "SuspendFile",
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION
      }, {
        name: "Quotes",
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "Notes",
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'LocmanUI',
        endpoint: config.apiGateway.UI_URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'LocmanQueries',
        endpoint: config.apiGateway.QUERIES_URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});



class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      status: {
        query: false,
        bugfix: false, 
        ui: false
      },
      nextBusCountdown: <Spinner />,
      modifications: {
        strings: <Spinner />,
        repositories: <Spinner />,
        files: <Spinner />,
        words:  <Spinner />
      },
      activeProjects: <Spinner />,
      projects: [],
      repositories: { repositories: [] },
      notes: <Spinner />,
      nextBusDate: this.predicNextBusDate()
    }
  }

  setNotes = (notes) => {
    this.setState({ notes })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  predicNextBusDate() {
    // Predicts Tuesday and Friday buses
    let date = moment();
    let day = date.day();
    let daysUntilNext;
    if (day === 0 || day === 1 || day === 2) {
        daysUntilNext = 2 - day;
    } else if (day === 3 || day === 4) {
        daysUntilNext = 5 - day;
    } else {
        daysUntilNext = (9 - day) % 7;
    }
    date.add(daysUntilNext, 'days');
    return date.format();
  }

  componentDidMount() {
    //const { nextBusDate } = this.state;

    API.get('Status', 'status')
    .then(res => {
      this.setState({ status: res })
    })
    .catch(err => {
      console.log(err)
    })

    API.get('NextUIBus', 'ui-bus/next-bus')
    .then(res => {
      this.setState({ ...res})
      this.setState({ nextBusCountdown: countdown(moment(this.state.nextBusDate)) })
      this.interval = setInterval(() => this.setState({ nextBusCountdown: countdown(moment(this.state.nextBusDate)) }), 60000)
      console.log(this.state.nextBusDate);
      console.log(moment(this.state.nextBusDate).utc().format('YYYY-MM-DD'))
      API.get('Notes', 'notes', { queryStringParameters: { projectDate: moment(this.state.nextBusDate).utc().format('YYYY-MM-DD') }})
      .then(res => {
        this.setState({ ...res })
      })
      .catch(err => {
        console.log(err)
      })
    })
    .catch(err => {
      console.log(err)
    })

    API.get('LatestUIProjects', 'ui-bus/latest-projects')
    .then(res => {
      this.setState({ ...res })
    })
    .catch(err => {
      console.log(err)
    })


  }
  
  renderOperations({ nextBusCountdown, overview, notes }) {
    return (
      <Box justify='center' gap='medium' fill>
          <Route exact path='/' render={() => <BusOperations nextBusCountdown={nextBusCountdown} overview={overview} repositories={this.state.repositories.repositories} nextBusDate={this.state.nextBusDate} notes={notes} setNotes={this.setNotes} />} />
      </Box>
    )
  }

  renderSearch() {
    return (
      <Search />
    )
  }

  renderBugfix() {
    return (
      <BugfixOperations />
    )
  }

  renderQueries() {
    return (
      <Box justify='center' direction='row-responsive' gap='medium' fill>
        <Text>Hello queries!</Text>
      </Box>
    )
  }

  renderQuotes() {
    return (
      <Quotes />
    )
  }
  
  render() {
    const{ nextBusCountdown,
      modifications,
      activeProjects,
      projects,
      status,
      notes,
    } = this.state

    return (
      <Router>
        <Grommet theme={theme}>
          <ResponsiveContext.Consumer>
            {size => (
              <Box>
                <Box pad='medium' justify='end'>
                  <Header size={size} status={status} />
                </Box>
                <Route exact path='/' render={() => this.renderOperations({ nextBusCountdown, overview: { modifications, activeProjects, projects }, notes })} />
                <Route exact path='/search' render={() => this.renderSearch()} />
                <Route exact path='/bugfix' render={() => this.renderBugfix()} />
                <Route exact path='/queries' component={Queries} />
                <Route path='/quotes' render={() => this.renderQuotes()} />
                <Box pad='small' height='xsmall'><Image src={qlikLogo} fit='contain'/></Box>
                <Box direction='row-responsive' align='center' justify='center' gap='small' pad='small'><Slack color='plain' /><Text size='xlarge' weight='bold'>Globalization</Text></Box>
              </Box>
            )}
          </ResponsiveContext.Consumer>
        </Grommet>
      </Router>
    )
  }
}

export default withAuthenticator (App)
