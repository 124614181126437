import React, { Component } from 'react'
import { Box, Text, Button, Layer, DateInput, TextArea, FileInput, Image } from 'grommet';
import Spinner from 'react-svg-spinner'
import { FormClose } from 'grommet-icons';
import { cloneDeep } from 'lodash'
import {API} from "aws-amplify";
import moment from 'moment'

export class Notes extends Component {
  constructor (props) {
    super(props)
    this.state = {
        selectedDate: props.nextBusDate,
        notes: props.notes,
        saving: Array.from(props.notes, x => false),
        deleting: Array.from(props.notes, x => false),
        showPopup: true,
        popupMsg: '',
        hasError: true
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ notes: nextProps.notes });  
  }

  setShow(show){
    this.setState({
      showPopup: show
    });
  }

  onDateChange = (event) => {
    const { setNotes } = this.props;
    const nextValue = event.value[1];
    this.setState({ selectedDate: nextValue });
    API.get('Notes', 'notes', { queryStringParameters: { projectDate: moment(nextValue).format('YYYY-MM-DD') }})
    .then(res => {
      this.setState({ ...res });
      setNotes(res.notes);
      this.setState({ saving: Array.from(res.notes, x => false), deleting:  Array.from(res.notes, x => false),});
    })
    .catch(err => {
      console.log(err)
      const msg = `Failed to change date.`
      this.setState({
        showPopup: true,
        popupMsg: msg,
        hasError: true
      })
    })
  }

  onNoteChange = (event, index) => {
    const { setNotes } = this.props;
    const { notes } = this.state;
    let nextNotes = cloneDeep(notes);
    nextNotes[index]['note'] = event.target.value;
    this.setState({ notes: nextNotes });
    setNotes(nextNotes);
  }

  async readFile (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      }
      reader.onerror = function (error) {
        reject(error)
      }
    })
  }

  onChangePic = async (event, index) => {
    const { setNotes } = this.props;
    const { notes } = this.state;
    let nextNotes = cloneDeep(notes);

    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const image = await this.readFile(fileList[0])
      nextNotes[index]['notePic'] = image;
      this.setState({ notes: nextNotes });
      setNotes(nextNotes);
    } else {
      nextNotes[index]['notePic'] = undefined;
      this.setState({ notes: nextNotes });
      setNotes(nextNotes);
    }
  }

  renderNote = ({ note, noteIndex }) => {
    const { saving, deleting } = this.state;
    return (
        <Box direction='row' key={`nodeIndex_${noteIndex}`} align='center'>
            <Box flex pad='xsmall'>
                <TextArea value={note.note} onChange={(event) => this.onNoteChange(event, noteIndex)} aria-label="Clarification note" />
            </Box>
            {<Box flex pad='xsmall'>
              <FileInput
                // id="notePic"
                // name="notePic"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => this.onChangePic(e, noteIndex)}
                messages={{
                  dropPrompt: 'Drag and drop',
                  // when there is already a file, the label should read
                  // "Replace File"
                  browse: note.notePic ? 'Replace file' : 'Browse',
                }}
              />
            </Box>}
            {note.notePic && <Box flex pad='xsmall'>
              <Image
                fit="cover"
                src={note.notePic}
              />
            </Box>}
            <Box pad='xsmall'><Button small='small' primary label={(saving[noteIndex] && <Spinner />) || "Save"} onClick={()=>this.onSave(noteIndex)}/></Box>
            <Box pad='xsmall'><Button small='small' secondary label={(deleting[noteIndex] && <Spinner />) || "Delete"} onClick={()=>this.onDelete(note._id, noteIndex)}/></Box>
        </Box>
    )
  }

  onSave = (index) => {
    const { notes, saving } = this.state;

    const myInit = {
      body: notes[index]
    }
    let nextSaving = cloneDeep(saving)
    nextSaving[index] = true;
    this.setState({ saving: nextSaving });

    API.post('Notes', `notes/${notes[index]['_id']}`, myInit)
    .then(res => {
      nextSaving[index] = false;
      this.setState({ saving: nextSaving });
    })
    .catch(err => {
      console.log(err);
      const msg = `Failed to save note.`
      this.setState({
        showPopup: true,
        popupMsg: msg,
        hasError: true
      })
    })
  }
  
  onDelete = (id, noteIndex) => {
    const { notes, deleting } = this.state;
    const { setNotes } = this.props;
    let nextDeleting = cloneDeep(deleting)
    nextDeleting[noteIndex] = true;
    this.setState({ deleting: nextDeleting });
    let nextNotes = cloneDeep(notes);
    nextNotes.splice(noteIndex, 1);
    this.setState({ notes: nextNotes });
    API.del('Notes', `notes/${id}`)
      .then(res => {
        this.setState({ notes: nextNotes });
        setNotes(nextNotes);
        nextDeleting[noteIndex] = false;
        this.setState({ deleting: nextDeleting });
      })
      .catch(err => {
        console.log(err);
        const msg = `Failed to delete note.`
        this.setState({
          showPopup: true,
          popupMsg: msg,
          hasError: true
        })
      })
  }
  

  render () {
    const { onNotesLayerClose, creatingNote, showPopup } = this.props;
    const { selectedDate, notes } = this.state;
    return (
        <Layer full="vertical" position="right" onClickOutside={() => onNotesLayerClose()}>
        <Box overflow="auto">
         <Box fill style={{ minWidth: '800px' }}>
           <Box
            direction="row"
            align="center"
            as="header"
            elevation="small"
            justify="between"
          >
            <Text margin={{ left: 'small' }}>Notes</Text>
            <Box>
                <DateInput format="yyyy-mm-dd" value={selectedDate} onChange={this.onDateChange} />
            </Box>
            <Button icon={<FormClose />} onClick={()=>onNotesLayerClose()} />
          </Box>
          {notes.length > 0 && <Box flex overflow="auto" pad="xsmall">
            {notes.map((note, noteIndex) => {
              return this.renderNote({ note, noteIndex });
            })}
          </Box>}
          {!notes.length && <Box flex overflow="auto" align='center' pad='large'>
            <span>No notes for this day!</span>
          </Box>}
          {creatingNote && <Box flex overflow="auto" align='center' pad='large'>
            <Spinner />
          </Box>}
        </Box>
        </Box>
        {showPopup && (
          <Layer
            position="center"
            onEsc={() => this.setShow(false)}
            onClickOutside={() => this.setShow(false)}
          >
            <Box pad='large' gap='medium'>
              <Text
                color={this.state.hasError ? "red" : "green"}
              >
                {this.state.popupMsg}
              </Text>
              <Button
                label="close"
                onClick={() => this.setShow(false)}
              />
            </Box>
          </Layer>
        )}
      </Layer>
    )
  }
}

export default Notes
