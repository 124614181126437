import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Anchor, Box, Button, Text, Select, DataTable, CheckBox, Layer } from 'grommet'
import { FormClose } from 'grommet-icons'
import { qlikBrand } from '../../branding'
import {API} from "aws-amplify"
import { cloneDeep } from 'lodash'
import AddNote from '../../components/Notes/addNote'

export class BusBreakdown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: [],
      options: [],
      value: '',
      repositories: props.repositories,
      showPopupSuspend: false,
      showPopupNote: false,
      popupMsg: '',
      hasError: false,
      stringSelected: undefined,
      repoSelected: undefined,
    }
  }

  
  onRemoveRepo = repo => {
    const { selected } = this.state
    const nextSelected = [...selected]
    nextSelected.splice(nextSelected.map((nextSelectedRepo) => { return nextSelectedRepo.name}).indexOf(repo.name), 1)
    this.setState({ selected: nextSelected })
  }

  renderRepoTag = repo => (
    <Button
      key={`repo_tag_${repo.name}`}
      href='#'
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        this.onRemoveRepo(repo)
      }}
      onFocus={event => event.stopPropagation()}
    >
      <Box
        align='center'
        direction='row'
        gap='xsmall'
        pad={{ vertical: 'xsmall', horizontal: 'small' }}
        margin='xsmall'
        background='brand'
        round='large'
      >
        <Text size='small' color='white'>
          {repo.name}
        </Text>
        <Box background='white' round='full' margin={{ left: 'xsmall' }}>
          <FormClose
            color='brand'
            size='small'
            style={{ width: '12px', height: '12px' }}
          />
        </Box>
      </Box>
    </Button>
  )

  renderOption = (option, index, options, state) => (
    <Box pad='small' background={state.active ? 'active' : undefined}>
      {option.name}
    </Box>
  )

  async setChecked (susRepo, susFile, event) {
    const repoId = susRepo.id;
    const fileId = susFile.fileId
    const { repositories } = this.state;
    const suspended = event.target.checked;
    const index = repositories.findIndex(repo => repo.id === repoId);
    const myInit = { 
      body: {
        repoId,
        fileId,
        suspended,
      }, 
    };
    API.post('SuspendFile', 'ui-bus/suspendFile', myInit)
    .then(res => {
      repositories[index].files.some((file) => {
        if(file.fileId === fileId) {
          file.suspended = suspended;
          return true;
        }
        return false;
      });
      const msg = suspended ? `Suspended File: ${susFile.path} in repo ${susRepo.name}` : `Resumed File: ${susFile.path} in repo ${susRepo.name}`
      this.setState({
        repositories: repositories,
        showPopupSuspend: true,
        popupMsg: msg,
        hasError: false
      })
    })
    .catch(err => {
      console.log(err);
      const msg = suspended ? `Failed to suspend File: ${susFile.path} in repo ${susRepo.name}` : `Failed to resume File: ${susFile.path} in repo ${susRepo.name}`
      this.setState({
        showPopupSuspend: true,
        popupMsg: msg,
        hasError: true
      })
    })
  }

  renderFilePath = ({repo, file }) => {
    const repoName = repo.name;
    const filePath = file.path;
    const {isBusLookAhead} = this.props;
    let urls = []
    urls.push({
      label: repoName,
      url: `https://github.com/qlik-trial/${repoName}`
    })
    let filePathSplit = filePath.split(/\/+|\\+/)
    filePathSplit.forEach((folder, index) => {
      let fullPath = filePathSplit.slice(0, index + 1).join('/')
      urls.push({
        label: folder,
        url: `https://github.com/qlik-trial/${repoName}/blob/${repo.defaultBranch || 'master'}/${fullPath}`
      })
    })
    return (
      <Box direction='row' gap='small'>
        {urls.map((url, index) => {
          let notLast = urls[index + 1]
          return (
            <Box key={`github_breadcrumbs_${url.url}`} direction='row' gap='small' align='center'>
              <Anchor href={url.url} label={url.label} />
              {notLast && <Text weight='bold'>/</Text>}
            </Box>
          )
        })
        }
        { repo.name !== 'pendo' && isBusLookAhead && <CheckBox
          checked={!!file.suspended}
          label="SUSPEND"
          onChange={(event) => this.setChecked(repo, file, event)}
        />}
      </Box>
    )
  }

  renderRepo = ({ repo }) => {
    const { isBusLookAhead } = this.props;
    const columns = [
      {
        property: 'name',
        header: 'Name',
        search: true,
        render: datum => (
          <Box direction='row'>
            <Text wordBreak="break-word">{datum.name}</Text>
          </Box>
        ),
      },
      {
        property: 'value',
        header: 'Value',
        search: true,
        render: datum => (
          <Box direction='row'>
            <Text wordBreak="break-word">{datum.value}</Text>
          </Box>
        ),
      },
      {
        property: 'comment',
        header: 'Comment',
        search: true,
        render: datum => (
          <Box direction='row'>
            <Text wordBreak="break-word">{datum.comment}</Text>
          </Box>
        ),
      }
    ]

    return (
    <Box key={`repo_${repo.name}_${repo.id}`} pad='small' gap='small' border={{color: qlikBrand.flood.turquoise}} round>
      <Box justify='center' align='center' direction='row-responsive' gap='small'>
        <Text size='large' weight='bold'>{repo.name}</Text>
        <Box direction='row' background='brand' pad={{left: 'small', right: 'small'}} round>{repo.stringsModified}</Box>
      </Box>
      <Box gap='small'>
      {repo.files.map(file => {
        return (
          <Box gap='xsmall' key={`renderRepo_files_${file.path}_${repo.stringsModified}`}>
            {this.renderFilePath({repo, file})}
            <Box direction='row'>
              {/* resizeble will only show max 50 rows */}
              {/* <DataTable resizeable={true} sortable columns={columns} data={file.delta} primaryKey='_id' /> */}
              {isBusLookAhead && <DataTable size='large' sortable columns={columns} data={file.delta} primaryKey='_id' onClickRow={({ datum })=>{this.onClickString(datum, repo)}} />}
              {
                !isBusLookAhead && <DataTable size='large' sortable columns={columns} data={file.delta} primaryKey='_id' />
              }
            </Box>
          </Box>)
      })}
      </Box>
    </Box>)
  }

  async onClickString(datum, repo) {
    this.setState({
        showPopupNote: true,
        stringSelected: datum,
        repoSelected: repo,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      options: nextProps.repositories
    }
  }

  setShowSuspend(show){
    this.setState({
      showPopupSuspend: show
    });
  }

  setShowPopupNote({showPopupNote, newNote}) {
    const { notes, setNotes, setCreatingNote } = this.props;
    this.setState({
      showPopupNote: showPopupNote
    })
    if(newNote) {
      let nextNotes = cloneDeep(notes);
      nextNotes.push(newNote);
      setNotes(nextNotes);
      setCreatingNote(false);
    }
  }

  render () {
    const { options, selected, repositories, showPopupSuspend, showPopupNote, stringSelected, repoSelected } = this.state
    const { isBusLookAhead, nextBusDate } = this.props;
    return (
      <Box>
        <Box pad='small' round>
          <Select
            closeOnChange={false}
            multiple
            valueKey="name"
            value={
              <Box wrap direction='row'>
                {selected && selected.length ? (
                  selected.map(index => this.renderRepoTag(repositories[index]))
                ) : (
                  <Box
                    pad={{ vertical: 'xsmall', horizontal: 'small' }}
                    margin='xsmall'
                  >
                    <Text weight='bold'>All Repositories</Text>
                  </Box>
                )}
              </Box>
            }
            options={options}
            selected={selected}
            onChange={({ selected: nextSelected }) => {
              this.setState({ selected: nextSelected.sort() })
            }}
            onSearch={text => {
              const exp = new RegExp(text, "i")
              this.setState({
                options: repositories.filter(o => exp.test(o.name))
              })
            }}
            onClose={() => this.setState({ options: repositories })}
          >
            {this.renderOption}
          </Select>
        </Box>
        <Box>
          <Box pad='medium' gap='small' fill>
            {selected && selected.length ? (
                selected.map((index) => {
                  return this.renderRepo({ repo: repositories[index] })
                })
              ) : (
                repositories.map((repo) => {
                  return this.renderRepo({ repo })
                })
            )}
          </Box>
        </Box>
        <Box>
          {isBusLookAhead && showPopupSuspend && (
            <Layer
              position="center"
              onEsc={() => this.setShowSuspend(false)}
              onClickOutside={() => this.setShowSuspend(false)}
            >
              <Box pad='large' gap='medium'>
                <Text
                  color={this.state.hasError ? "red" : "green"}
                >
                  {this.state.popupMsg}
                </Text>
                <Button
                  label="close"
                  onClick={() => this.setShowSuspend(false)}
                />
              </Box>
            </Layer>
          )}
          {isBusLookAhead && showPopupNote && (<AddNote
            stringSelected={stringSelected}
            repoSelected={repoSelected}
            nextBusDate={nextBusDate}
            onExit={this.setShowPopupNote.bind(this)}
          />)}
        </Box>
      </Box>
      
    )
  }
}

BusBreakdown.propTypes = {
  repositories: PropTypes.array.isRequired
}


export default BusBreakdown
