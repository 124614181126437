import React, { Component } from 'react'
import { Box, Button, Heading, Image, Text, Layer } from 'grommet'
import Spinner from 'react-svg-spinner'
import { Chat } from 'grommet-icons'
import qlikBrand from '../../branding'
import DisplayImage from '../../components/DisplayImageBugfix'
import clone from 'lodash/clone'
import { API } from 'aws-amplify'
export class QueriesOperations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      queries: [],
      fetchQueriesInProgress: true,
      imageExpanded: []
    }
  }

  handleCloseImage(index) {
    const { imageExpanded } = this.state

    let newImageExpanded = clone(imageExpanded)
    newImageExpanded[index] = false
    this.setState({ imageExpanded: newImageExpanded })
  }

  renderQueries() {
    const { queries, fetchQueriesInProgress, imageExpanded } = this.state

    if (fetchQueriesInProgress) {
      return (<Box align='center'><Spinner size='80px'/></Box>)
    }
    if (!queries.length) {
      return (
        <Box align='center'>No queries!</Box>
      )
    } else {
      return (
        <Box align='center' gap='small'>
          {queries.map((query, index) => (
            <Box background='white' width='large' pad='medium' gap='small' round>
              <Box direction='row' gap='xsmall'>
                {query.project && <Box><Text weight='bold'>{query.project}</Text></Box>}
                {query.language && <Box pad='xxsmall' background={qlikBrand.flood.turquoise} round>{query.language}</Box>}
              </Box>
              {query.path && <Box>{query.path}</Box>}
              <Box>
                {query.type && <Box><Text>{query.type}</Text></Box>}
              </Box>
              <Box>
                {query.source && <Box direction='row' gap='small'><Text weight='bold'>Source:</Text><Text>{query.source}</Text></Box>}
                {query.query && <Box direction='row' gap='small'><Text weight='bold'>Query:</Text><Text>{query.query}</Text></Box>}
                {query.image && <Button onClick={()=>{
                  let newImageExpanded = clone(imageExpanded)
                  newImageExpanded[index] = true
                  this.setState({ imageExpanded: newImageExpanded })
                }}><Box height='xsmall'><Image src={query.image} fit='contain'/></Box></Button>}
                {query.answer && <Box direction='row' gap='small'><Text weight='bold'>Answer:</Text><Text>{query.answer}</Text></Box>}
                {!query.answer && <Box direction='row' gap='small' justify='end'><Button icon={<Chat color='red'/>} href='https://queries.locman.cloud'/></Box>}
              </Box>
              {imageExpanded[index] && <Layer onEsc={()=>this.handleCloseImage(index)} onClickOutside={()=>this.handleCloseImage(index)}><DisplayImage image={query.image} /></Layer>}
            </Box>
          ))}
        </Box>
      )
    }
  }

  async componentDidMount () {
    API.get('LocmanQueries', 'queries')
      .then(resQueries => {
        this.setState({ fetchQueriesInProgress: false, queries: resQueries })
        let initialExp = resQueries.map(query => false)
        this.setState({ imageExpanded: initialExp })
      })
  }

  render () {
    const { queries, fetchQueriesInProgress } = this.state
    return (
      <Box align='stretch' fill='horizontal' jusify='center'>
        <Box align='center'><Heading level='2'>Queries</Heading></Box>
        {this.renderQueries({
          queries,
          fetchQueriesInProgress
        })}
      </Box>
    )
  }
}

export default QueriesOperations