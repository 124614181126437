export const qlikBrand = {
  core: {
    // Qlik Green is a CORE Brand Ingredient —its is a required ingredient in all assets.
    green: '#009845'
  },
  flood: {
    // When choosing a base or flood color, use 1 of the 6 flood colors.
    // Only one flood color should be used as a dominate color per asset.
    tanzanite: '#1C355E',
    slate: '#244B5A',
    turquoise: '#006580',
    aquamarine: '#10CFC9',
    wine: '#870064',
    amethyst: '#655DC6',
    stone: '#C4CFDA'
  },
  support: {
    // Colors in this palette play a supporting role. They can be used as fills or floods on smaller areas.
    sapphire: '#005CB9',
    emerald: '#006937',
    ruby: '#E7004C',
    grey: '#54565A'
  },
  highlight: {
    // Highlight colors should be used sparingly and when there is a need for additional colors
    // in illustrations, and data visualizations.
    coral: '#FF8E9F',
    topaz: '#FFC72A',
    peridot: '#C2D500'
  },
  font: 'Source Sans Pro'
}
export const theme = {
  global: {
    colors: {
      brand: qlikBrand.core.green
    },
    font: {
      family: qlikBrand.font,
      size: '14px',
      height: '20px'
    }
  }
}

export default qlikBrand
