
import React, { useState } from 'react';
import {
  Box,
  Layer,
  DataTable,
  Text,
  TextInput,
  Button,
} from 'grommet';

import { Search } from 'grommet-icons';
import { qlikBrand } from '../../branding';

import utils from '../../libs/utils';

const DownloadQuote = (props) => {
  const [data, setData] = useState(null);

  const onSearch = () => {
    const input = document.getElementById('search_quote')?.value || '';
    if (!input) return setData(props.quoteList);

    const rows = props.quoteList.filter(({ id, ...res }) =>
      Object.values(res).some(v => v.toLowerCase().includes(input.toLowerCase().trim()))
    );

    setData(rows);
  };

  return (
    <Layer
      full
      modal={true}
      margin='none'
      position="center"
      onEsc={() => props.onExit({ showDownloadPage: false })}
      onClickOutside={() => props.onExit({ showDownloadPage: false })}
    >
      <Box alignSelf='stretch' overflow='scroll'>
        <Button
          alignSelf='end'
          label="close"
          pad='large'
          margin='large'
          onClick={() => props.onExit({ showDownloadPage: false })}
        />
        <Text alignSelf='center' size='large' weight='bold'>Download Quotes</Text>

        <Box alignSelf='center' pad='medium' gap='medium'>
          <Box alignSelf='end' pad='medium'>
            <TextInput
              onSelect={() => { }}
              size='large'
              id='search_quote'
              placeholder="Search"
              onChange={onSearch}
              icon={<Search />}
            />
          </Box>
          <DataTable align='center'
            columns={props.columns}
            data={data || props.quoteList || []}
            paginate={true}
            step={10}
            primaryKey='id'
          />
        </Box>
      </Box>
      <Box alignSelf='center' pad='medium'>
        <Button
          primary
          color={qlikBrand.flood.turquoise}
          gap='large'
          label="Download"
          size="large"
          hoverIndicator
          onClick={() => utils.exportCSV(props.columns, (data || props.quoteList))}
        />
      </Box>
    </Layer>
  );
};

export default DownloadQuote;