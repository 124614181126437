import React, { Component } from 'react'
import {cloneDeep, isEqual} from 'lodash'
import {API} from "aws-amplify";
import { parse } from 'csv-parse/lib/sync'
import { 
  Box,
  Layer,
  Form,
  FormField,
  Text,
  Select,
  TextInput,
  Button,
  Spinner,
  Notification
} from 'grommet'

import configs from './configs'
export class UpdateQuote extends Component {
  constructor (props) {
    super(props)
    this.defaultValue = this.props.defaultValue;
    this.state = {
      newQuoteValue: cloneDeep(this.defaultValue),
      submitting: false,
      failed: false,
      canSubmit: false
    }
  }

  onReset() {
    this.setState({
      newQuoteValue: cloneDeep(this.defaultValue),
      canSubmit: false,
    })
  }

  onChangeField(nextValue, { touched }) {
    const changed = !isEqual(this.defaultValue, nextValue);
    this.setState({
      newQuoteValue: nextValue,
      canSubmit: changed,
    });
  }


  onClickCloseMsg() {
    this.setState({
      failed: false
    })
  }

  onClickSubmit() {
    const {newQuoteValue} = this.state;
    this.setState({
      submitting: true
    })
    const data = {
      body: {
        newQuoteValue,
      }
    }
    API.post('Quotes', 'quotes/update', data)
      .then((res) => {
        this.setState({
          newQuoteValue: cloneDeep(this.defaultValue),
          submitting: false,
          failed: false
        })
        const quoteList = parse(res.quotes, {
          columns: true,
          skip_empty_lines: true
        });
        quoteList.reverse();
        this.props.onExit({
          showUpdatePage: false,
          quoteList,
        });
      })
      .catch(err => {
        console.log(err)
        this.setState({
          submitting: false,
          failed: true
        })
      })
  }

  render () {
    const {
      newQuoteValue,
      submitting,
      failed,
      canSubmit
    } = this.state

    return (<Layer
      modal={true}
      margin='large'
      position="center"
      pad='large'
      onEsc={() => this.props.onExit({showAddPage: false})}
      onClickOutside={() => this.props.onExit({showAddPage: false})}
    >
      <Box alignSelf='stretch' overflow='scroll'>
        <Text pad='large' alignSelf='center' size='large' weight='bold'>Quote Details</Text>
        <Box alignSelf='center' pad='small' gap='xxsmall'>
          <Form
            value={newQuoteValue}
            onChange={this.onChangeField.bind(this)}
            onSubmit={this.onClickSubmit.bind(this)}
            onReset={this.onReset.bind(this)}
          >
            <Box direction="row" gap="xxsmall">
              <FormField name="product" htmlFor="product" label="product" required>
                <TextInput id="product" name="product" />
              </FormField>
              <FormField name="orderedIn" htmlFor="orderedIn" label="orderedIn" required>
                <Select
                  id="orderedIn"
                  name="orderedIn"
                  options={configs.months}
                />
              </FormField>
              <FormField name="handbackDate" htmlFor="handbackDate" label="handbackDate" required>
                <Select
                  id="handbackDate"
                  name="handbackDate"
                  options={configs.months}
                />
              </FormField>
              <FormField name="language" htmlFor="language" label="language" required>
                <Select
                  id="language"
                  name="language"
                  options={configs.locales}
                />
              </FormField>
              <FormField name="vendor" htmlFor="vendor" label="vendor" required>
                <Select
                  id="vendor"
                  name="vendor"
                  options={configs.vendors}
                />
              </FormField>
              <FormField name="type" htmlFor="type" label="type" required>
                <TextInput id="type" name="type" />
              </FormField>
            </Box>
            <Box direction="row" gap="medium">
              <FormField name="handoffName" htmlFor="handoffName" label="handoffName" required>
                <TextInput id="handoffName" name="handoffName" />
              </FormField>
              <FormField name="generatedFromMetrics" htmlFor="generatedFromMetrics" label="generatedFromMetrics" required>
                <TextInput id="generatedFromMetrics" name="generatedFromMetrics" />
              </FormField>
              <FormField name="managementFee" htmlFor="managementFee" label="managementFee" required>
                <TextInput id="managementFee" name="managementFee" />
              </FormField>
              <FormField name="actualInEUR" htmlFor="actualInEUR" label="actualInEUR" required>
                <TextInput id="actualInEUR" name="actualInEUR" />
              </FormField>
              <FormField name="jiraID" htmlFor="jiraID" label="jiraID">
                <TextInput id="jiraID" name="jiraID" />
              </FormField>
            </Box>
            <Box>
              <FormField name="comment" htmlFor="comment" label="comment">
                <TextInput id="comment" name="comment" />
              </FormField>
            </Box>
            <Box direction="row" justify="between" gap="medium">
              <Button 
                label="Cancel"
                disabled={submitting}
                onClick={() => this.props.onExit({showAddPage: false})}
              />
              <Button type="reset" label="Reset Form" disabled={submitting}/>
              <Button type="submit" primary label="Update" disabled={!canSubmit || submitting}/>
            </Box>
          </Form>
        </Box>
        <Box direction='row' alignSelf='center' pad='medium' margin='medium'>
          {submitting && (<Box direction='row' gap="small">
            <Spinner size='small' />
            <Text>Update In Progress...</Text>
            </Box>
          )}
        </Box>
        {
          failed && <Box align='center' pad="medium">
            <Notification
              background='red'
              title="Error"
              message="Something went wrong!"
              onClose={this.onClickCloseMsg.bind(this)}
            />
          </Box>
        }
      </Box>
    </Layer>
    )
  }
}

export default UpdateQuote
