import React, { Component } from 'react'
import { 
  Box,
  Grid,
  TextInput,
  Button,
  Text,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Anchor
} from 'grommet'
import { Search as SearchIcon } from 'grommet-icons'
import Spinner from 'react-svg-spinner'
// import axios from 'axios'
import { API } from 'aws-amplify'

export class Search extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchText: '',
      exactValues: [],
      exactIds: [],
      results: [],
      searchInProgress: false,
      searchFailed: false,
      firstSearch: true,
    }
  }

  async handleSubmit (){
    if (this.state.searchText) {
      this.setState({
        searchInProgress: true,
        firstSearch: false
      })
      API.get('LocmanUI', `resources/searchResource/${this.state.searchText}`)
      .then(res => {
        const { exactValues, exactIds, results } = res
        this.setState({
          exactValues,
          exactIds,
          results,
          searchInProgress: false
        })
      })
      .catch(err => {
        this.setState({
          searchInProgress: false,
          searchFailed: true
        })
      })
    }
  }

  handleSearchTextChange (searchText) {
    this.setState({
      searchText
    })
  }

  renderExactMatches (exactValues) {
    if (!exactValues.length) return null
    return (
      <Box gap='small'>
        <Text weight='bold'>Exact Matches</Text>
        {exactValues.map((exactValue) => {
          return (
            <Card pad="small" gap="small" width='large' background='white' border={{color: 'red'}}>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'ID:'}</Text><Text>{`${exactValue.name}`}</Text></Box>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'Value:'}</Text><Text>{`${exactValue.value}`}</Text></Box>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'Comment:'}</Text><Text>{`${exactValue.comment}`}</Text></Box>
              <Box direction='row' gap='xsmall'>
                <Text>{`Location:`}</Text>
                <Anchor href={`https://github.com/${exactValue.owner}/${exactValue.repository}/blob/${exactValue.branchName}/${exactValue.path}`} label={`${exactValue.repository}/${exactValue.path}`}/>
              </Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col">
                      <Text>Locale</Text>
                    </TableCell>
                    <TableCell scope="col">
                      <Text>Translation</Text>
                    </TableCell>
                  </TableRow>
                 </TableHeader>
                 <TableBody>
                   {exactValue.translations.map((translation, index) => {
                     return (
                      <TableRow key={`${translation._id}-${index}`}>
                        <TableCell><strong>{translation.locale}</strong></TableCell>
                        <TableCell><Text>{translation.value}</Text></TableCell>
                      </TableRow>
                     )
                   })}
                 </TableBody>
              </Table>
            </Card>
          )
        })}
      </Box>
    )
  }

  renderExactIDs (exactIds) {
    if (!exactIds.length) return null
    return (
      <Box gap='small'>
        <Text weight='bold'>Exact IDs</Text>
        {exactIds.map((exactId) => {
          return (
            <Card key={exactId._id} pad="small" gap="small" width='large' background='white' border={{color: 'red'}}>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'ID:'}</Text><Text>{`${exactId.name}`}</Text></Box>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'Value:'}</Text><Text>{`${exactId.value}`}</Text></Box>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'Comment:'}</Text><Text>{`${exactId.comment}`}</Text></Box>
              <Box direction='row' gap='xsmall'>
                <Text>{`Location:`}</Text>
                <Anchor href={`https://github.com/${exactId.owner}/${exactId.repository}/blob/${exactId.branchName}/${exactId.path}`} label={`${exactId.repository}/${exactId.path}`}/>
              </Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col">
                      <Text>Locale</Text>
                    </TableCell>
                    <TableCell scope="col">
                      <Text>Translation</Text>
                    </TableCell>
                  </TableRow>
                 </TableHeader>
                 <TableBody>
                   {exactId.translations.map(translation => {
                     return (
                      <TableRow key={`${translation._id}`}>
                        <TableCell><strong>{translation.locale}</strong></TableCell>
                        <TableCell><Text>{translation.value}</Text></TableCell>
                      </TableRow>
                     )
                   })}
                 </TableBody>
              </Table>
            </Card>
          )
        })}
      </Box>
    )
  }

  renderNonExactMatches (results) {
    if (!results.length) return null
    return (
      <Box gap='small'>
        <Text weight='bold'>Results</Text>
        {results.map((result) => {
          return (
            <Card pad="small" gap="small" width='large' background='white'>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'ID:'}</Text><Text>{`${result.name}`}</Text></Box>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'Value:'}</Text><Text>{`${result.value}`}</Text></Box>
              <Box direction='row' gap='xsmall'><Text weight='bold'>{'Comment:'}</Text><Text>{`${result.comment}`}</Text></Box>
              <Box direction='row' gap='xsmall'>
                <Text weight='bold'>{'Location:'}</Text>
                <Anchor href={`https://github.com/${result.owner}/${result.repository}/blob/${result.branchName}/${result.path}`} label={`${result.repository}/${result.path}`}/>
              </Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell scope="col">
                      <Text>Locale</Text>
                    </TableCell>
                    <TableCell scope="col">
                      <Text>Translation</Text>
                    </TableCell>
                  </TableRow>
                 </TableHeader>
                 <TableBody>
                   {result.translations.map(translation => {
                     return (
                      <TableRow key={`${translation._id}`}>
                        <TableCell><strong>{translation.locale}</strong></TableCell>
                        <TableCell><Text>{translation.value}</Text></TableCell>
                      </TableRow>
                     )
                   })}
                 </TableBody>
              </Table>
            </Card>
          )
        })}
      </Box>
    )
  }

  renderResults (exactValues, exactIds, results, firstSearch) {
    if (firstSearch) {
      return null
    }
    if (!exactValues.length && !exactIds.length && !results.length) {
      return (
        <Text size='xxlarge'>There were no results</Text>
      )
    }
    return (
      <Box gap='small'>
        {this.renderExactMatches(exactValues)}
        {this.renderExactIDs(exactIds)}
        {results.length && this.renderNonExactMatches(results)}
      </Box>
    )
  }

  async keyPress (e){
    if(e.keyCode === 13){
      await this.handleSubmit()
    }
  }

  render () {
    const {
      exactValues,
      exactIds,
      results,
      searchInProgress,
      searchFailed,
      firstSearch
    } = this.state
    return (
      <Grid
        areas={[
          { name: 'searchBox', start: [0, 0], end: [1, 0] },
          { name: 'results', start: [0, 1], end: [1, 1] }
        ]}
        columns={['flex']}
        rows={['auto', 'flex']}
        gap='small'
        fill
      >
        <Box gridArea='searchBox' align='center'>
          <Box direction='row' gap='small' width='large'>
            <TextInput
              placeholder='Enter a string or ID'
              icon={<SearchIcon />}
              onChange={(event)=>this.handleSearchTextChange(event.target.value)}
              onKeyDown={(e)=>this.keyPress(e)}
            />
            <Button label='Search' onClick={()=>this.handleSubmit()}/>
          </Box>
        </Box>
        <Box gridArea='results' align='center'>
          {searchInProgress && <Spinner size="100px"/>}
          {searchFailed && <Text size='xxlarge'>Something went wrong :(</Text>}
          {!searchFailed && !searchInProgress && this.renderResults(exactValues, exactIds, results, firstSearch)}
        </Box>
      </Grid>
    )
  }
}

export default Search